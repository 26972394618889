import {useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";

export const useNews = ({PAGE_SIZE = 35, initialNewsFeed, getItems, ticker = ""}) => {

  const router = useRouter();
  const locale = router.locale;
  const rssTopRef = useRef<HTMLDivElement>();

  const [pagination,setPagination] = useState({items: initialNewsFeed && initialNewsFeed.items, initial: true, skip: 0, limit: PAGE_SIZE, length: initialNewsFeed && initialNewsFeed.length})
  const [paginationDisabled,setPaginationDisabled] = useState(false)
  const [details,setDetails] = useState<any>({})
  const hasNext = pagination.length > pagination.skip+pagination.limit
  const hasPrev = pagination.skip > 0

  async function nextPage() {
    const hasNext = pagination.length > pagination.skip+pagination.limit
    if (!hasNext) return
    const skip = pagination.skip + PAGE_SIZE
    setTimeout(() => rssTopRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: "auto"}),1)
    const items = await loadItems(skip,pagination.limit)
    if (items) {
      setPagination({...pagination, items:items.items, skip, initial: false})
      preRender(pagination.length,skip,pagination.limit).then(() => {})
    }
  }

  async function preRender(length,skip,limit) {
    const hasNext = length > skip+limit
    skip = skip + limit
    if (hasNext) {
      await loadItems(skip,limit,true)
    }
  }

  async function prevPage() {
    const hasPrev = pagination.skip > 0
    if (!hasPrev) return
    const skip = pagination.skip - PAGE_SIZE
    setTimeout(() => rssTopRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: "auto"}),1)
    const items = await loadItems(skip,pagination.limit)
    if (items) {
      setPagination({...pagination, items:items.items, skip, initial: false})
    }

  }

  useEffect(() => {
    if (!pagination.initial) return
    if (initialNewsFeed) {
      setPagination({items: initialNewsFeed && initialNewsFeed.items, initial: true, skip: 0, limit: PAGE_SIZE, length: initialNewsFeed && initialNewsFeed.length})
      preRender(initialNewsFeed.length, 0, PAGE_SIZE).then(() => {})
      return
    }
    new Promise(async (resolve) => {
      const _items = await loadItems(0,PAGE_SIZE)
      const {items,...details} = _items;
      if (items) {
        setDetails(details)
        setPagination({...pagination, items, skip: 0, initial: false, length: details.length || items.length})
        preRender(items.length || pagination.length, 0, pagination.limit).then(() => {})
      }
      resolve(null)
    }).then(() => {})
  },[ticker])

  async function loadItems(skip,limit, prerender = false) {
    try {
      if (!prerender) setPaginationDisabled(true)
      const homeItems = await getItems(skip, limit, locale)
      return homeItems;
    } finally {
      if (!prerender) setPaginationDisabled(false)
    }
  }

  useEffect(() => {
    (async() => {
      if (pagination.initial) return

    })()
  },[pagination])

  return {pagination, details, paginationDisabled, hasNext, hasPrev, prevPage, nextPage, rssTopRef}

}
import {CompanyLinksResponse} from '../../models/api/company-links.response';
import {CompanyResponse} from '../../models/api/company.response';
import {ApiRoutes} from '../../constants/api-routes.const';
import {getJson} from "../../helpers/getJson";

export class CompanyApiService {
  public static fetchCompanyLinks(ticker: string): Promise<CompanyLinksResponse[]> {
    return fetch(ApiRoutes.Company.getLinksByTicker(ticker)).then((response) => getJson(response));
  }

  public static async fetchAllCompany(): Promise<CompanyResponse[]> {
      //TODO: Standardize error reporting to avoid invalid json error with hidden error status response
    return fetch(ApiRoutes.Company.getAll()).then(
        async (response) => {
          if (response.ok) {
            return await response.json()
          } else {
            throw new Error("Error in response. Status:"+response.status)
          }
        }
    ).catch(reason => {
        console.error("fetch all company failed. Reason:",reason)
        throw new Error(reason)
    });
  }

  public static async fetchCompanyDetails(tickers: string[]): Promise<CompanyResponse> {
    const res = await fetch(ApiRoutes.Company.getHomeStocksData(tickers))
    if (res.ok) {
      return res.json();
    } else {
      throw new Error(`Couldn't fetch ${res.url}, status: ${res.status}`)
    }
  }

}

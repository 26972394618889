import {CompanyApiService} from './api/company.api.service';
import {Company} from '../models/company.model';
import {RssApiService, RssItemResponse} from './api/rss.api.service';
import {languages} from "../constants/languages";

export class CompanyService {
  public static getCompanyLinks(ticker: string): Promise<any> {
    try {
      return CompanyApiService.fetchCompanyLinks(ticker);
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public static async getWatchlistNews(ticker,locale,skip,limit): Promise<any> {

    const _companyLinks = await this.getCompanyLinks(ticker);
    const companyLinks = _companyLinks && _companyLinks.length > 0 ? _companyLinks[0] : null

    const defaultNewsUrl = 'https://feeds.finance.yahoo.com/rss/2.0/headline?s=' + ticker

    let allNewsUrl = defaultNewsUrl;

    let hungarianFeedNotAvailableNotice = false

    for (const lan of Object.entries(languages)) {
      if (locale === lan[0]) {
        if (companyLinks && companyLinks["rssNews"+lan[1]]) {
          allNewsUrl = companyLinks["rssNews"+lan[1]]
        } else {
          hungarianFeedNotAvailableNotice = true
        }
      }
    }

    let allNewsFeed = await RssApiService.getItemsByUrlForWatchlist(allNewsUrl,skip,limit);

    return {hungarianFeedNotAvailableNotice,...allNewsFeed}

  }

  public static async getCompanyNews(ticker,locale,companyLinks?): Promise<{pressReleaseFeed:(RssItemResponse&any)[],allNewsFeed:(RssItemResponse&any)[]}> {
    const pressReleaseUrl = companyLinks ? companyLinks.rssPressRelease : '';
    const pressReleaseFeed = []
    const allNewsFeed = []

    let allNewsUrl = 'https://feeds.finance.yahoo.com/rss/2.0/headline?s=' + ticker;

    for (const lan of Object.entries(languages)) {
      if (locale === lan[0]) {
        if (companyLinks && companyLinks["rssNews"+lan[1]]) {
          allNewsUrl = companyLinks["rssNews"+lan[1]]
        }
      }
    }

    if (pressReleaseUrl) {
      const pressReleaseFeedItems = await RssApiService.getItemsByUrl(pressReleaseUrl);
      pressReleaseFeedItems.forEach((res) => pressReleaseFeed.push({...res,rssUrl:pressReleaseUrl}));
    }

    if (allNewsUrl) {
      const allNews = await RssApiService.getItemsByUrl(allNewsUrl);
      allNews.forEach((res) => allNewsFeed.push({...res,rssUrl:allNewsUrl}));
    }

    return {pressReleaseFeed,allNewsFeed: ticker.includes('.') ? allNewsFeed : null}

  }

  public static getAllCompany(): Promise<Company[]> {
    return CompanyApiService.fetchAllCompany();
  }

  public static getCompanies(tickers: string[]): Promise<any> {
    return CompanyApiService.fetchCompanyDetails(tickers)
  }

  public static async getTickers(): Promise<string[]> {
    return (await CompanyApiService.fetchAllCompany()).map((company) => company.ticker);
  }
}
